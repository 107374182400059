<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="8">
          <el-form-item label="教学名称">
            <el-input v-model="queryCourseName" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="courseList" highlight-current-row style="width: 100%">
      <el-table-column prop="courseId" label="courseId"></el-table-column>
      <el-table-column prop="courseName" label="名称"></el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间"></el-table-column>
      <el-table-column prop="status" label="状态" :formatter="formatStatus" width="200"> </el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button size="small" @click="onEditCourse(scope.$index, scope.row)">修改</el-button>
          <el-button size="small" @click="onCopy(scope.$index, scope.row)">复制</el-button>
          <el-button type="danger" size="small" @click="onDeleteCourse(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
const LEN = 20;

import { aidaCourseApi } from "@/api/aidaCourseApi";
import { epochToDateString } from "@/util/TimeUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
export default {
  data() {
    return {
      queryCourseName: "",
      courseList: [],
      currentPage: 1,
      totalPage: 10,
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    formatStatus(row, column) {
      const status = row[column.property];
      if (status == 0) {
        return "保存";
      } else if (status == 1) {
        return "发布";
      } else if (status == 2) {
        return "停售";
      } else {
        return "未知";
      }
    },
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      let start = (this.currentPage - 1) * LEN;
      this.onQuery();
    },
    //获取列表
    onQuery() {
      let start = (this.currentPage - 1) * LEN;
      console.log("#1", this.queryCourseName, start, LEN);
      aidaCourseApi
        .queryCourse(this.queryCourseName, start, LEN)
        .then((ret) => {
          if (ret.code === 0) {
            console.log("--ret.data.data--", ret.data);
            this.courseList = ret.data;
            this.totalPage = ret.totalPage;
          } else {
            alertMessage(ret.msg, "warning");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onDeleteCourse(index, row) {
      let msg = "确定删除该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          aidaCourseApi.deleteCourse(row.courseId).then((res) => {
            if (res.code === 0) {
              alertMessage("删除成功", "success");
              this.courseList.splice(index, 1);
            } else {
              alertMessage(res.msg, "warning");
            }
          });
        })
        .catch(() => {});
    },
    onEditCourse(index, row) {
      this.$router.push({
        name: "course-edit",
        params: { id: row.courseId },
      });
    },
    onCopy(index, row) {
      let msg = "确定复制该项吗？";
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          aidaCourseApi.copyCourse(row.courseId).then((res) => {
            if (res.code === 0) {
              alertMessage("复制成功", "success");
              this.onQuery();
            } else {
              this.$message({
                type: "info",
                message: "已取消复制",
              });
            }
          });
        })
        .catch(() => {});
    },
  },
  watch: {
    queryCourseName: function(newVal, oldVal) {
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped></style>
